<template>
  <validation-observer ref="create-delivery-notes">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor"
                  label-for="delivery-notes-provider"
                >
                  <BaseSelect
                    id="delivery-notes-provider"
                    :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
                    :value="model.provider"
                    @input="update('provider', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Estado"
                label-for="delivery-notes-status"
              >
                <StatusSelect
                  id="delivery-notes-status"
                  :value="model.status"
                  :type="statusType"
                  @input="update('status', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Pedido relacionado"
                label-for="delivery-notes-order"
              >
                <BaseSelect
                  id="delivery-notes-order"
                  label="number"
                  :resource="$data.$constants.RESOURCES.RESOURCE_ORDERS"
                  :value="model.order"
                  @input="update('order', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Factura relacionada"
                label-for="delivery-notes-purchase-invoice"
              >
                <BaseSelect
                  id="delivery-notes-purchase-invoice"
                  :value="model.purchase_invoice"
                  resource="purchase-invoices"
                  label="number"
                  @input="update('purchase_invoice', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Fecha"
                label-for="delivery-notes-date"
              >
                <b-form-datepicker
                  id="delivery-notes-date"
                  :value="model.delivery_note_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha"
                  :label-help="null"
                  @input="update('delivery_note_date', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Adjuntar Archivos">
                <FileDropZone
                  ref="documentation-input"
                  :files="files"
                  @removed-file="removedFiles.push($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { DELIVERY_NOTES_STATUS_TYPE } from '@/api/status-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'DeliveryNotesForm',
  components: {
    BaseSelect,
    FileDropZone,
    StatusSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: DELIVERY_NOTES_STATUS_TYPE,
      removedFiles: [],
    }
  },
  computed: {
    files() {
      return this.model.documentation
    },
  },
  methods: {
    validateForm() {
      return this.$refs['create-delivery-notes'].validate()
    },
    getDocumentationData() {
      return { documentation: this.$refs['documentation-input'].getFiles(), removedFiles: this.removedFiles }
    },
  },
}
</script>

<style scoped>
</style>
